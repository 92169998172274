var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"bg-white p-3 rounded shadow-sm"},[_c('h1',[_vm._v("Pemusnahan Darah")]),_c('b-form-group',{staticClass:"mt-5 w-100",attrs:{"label":"Masukkan Barcode","label-for":"barcode-input"}},[_c('b-form-input',{attrs:{"id":"barcode-input","type":"text","placeholder":"Masukkan barcode"},on:{"input":_vm.debouncedCheckingBarcodes},model:{value:(_vm.formData.barcode),callback:function ($$v) {_vm.$set(_vm.formData, "barcode", $$v)},expression:"formData.barcode"}})],1),_c('b-table',{attrs:{"responsive":"","items":_vm.tableForm.items,"fields":_vm.tableForm.fields,"busy":_vm.tableForm.isBusy},scopedSlots:_vm._u([{key:"cell(reason_failed)",fn:function({ item }){return [_c('b-form-select',{attrs:{"options":[
            { value: '', text: 'Pilih Alasan Penolakan' },
            { value: 'BOCOR', text: 'BOCOR' },
            { value: 'MISLEK', text: 'MISLEK' },
            { value: 'OVER VOLUME', text: 'OVER VOLUME' },
            { value: 'CLOT', text: 'CLOT' },
            { value: 'AGREGAT', text: 'AGREGAT' },
            { value: 'FIBRIN', text: 'FIBRIN' },
            { value: 'LIPEMIK', text: 'LIPEMIK' },
            { value: 'TC MERAH', text: 'TC MERAH' },
            { value: 'REAKTIF', text: 'REAKTIF' },
            { value: 'MAYOR', text: 'MAYOR' },
            { value: 'FELNOKTOMI', text: 'FELNOKTOMI' },
            { value: 'KADALUARSA', text: 'KADALUARSA' },
          ]},model:{value:(item.reason_failed),callback:function ($$v) {_vm.$set(item, "reason_failed", $$v)},expression:"item.reason_failed"}})]}},{key:"cell(note)",fn:function({ item }){return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Masukkan catatan"},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})]}},{key:"cell(action)",fn:function({ item, index }){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.tableForm.items.splice(index, 1)}}},[_c('i',{staticClass:"ri-delete-bin-6-line"})])]}}])}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('base-button',{attrs:{"variant":"primary","text":"Simpan"},on:{"click":_vm.handleSubmit}})],1)],1),_c('section',{staticClass:"bg-white p-3 rounded shadow-sm mt-5"},[_c('h3',[_vm._v("Daftar Pemusnahan")]),_c('div',{staticClass:"d-flex align-items-end justify-content-between"},[_c('p',{staticClass:"mb-0 mr-3"},[_vm._v("Semua ("+_vm._s(_vm.tableList.totalRows)+")")]),_c('b-input-group',{staticClass:"w-50"},[_c('b-input-group-prepend',[_c('b-button',{staticClass:"border-right-0 pr-0 pl-2",attrs:{"variant":"outline-black-50"}},[_c('i',{staticClass:"ri-search-line remix-icon text-primary"})])],1),_c('b-form-input',{staticClass:"border-left-0",attrs:{"type":"search","placeholder":"Search"},on:{"input":_vm.debounceSearch},model:{value:(_vm.tableList.searchQuery),callback:function ($$v) {_vm.$set(_vm.tableList, "searchQuery", $$v)},expression:"tableList.searchQuery"}})],1)],1),_c('b-table',{staticClass:"mt-3",attrs:{"responsive":"","items":_vm.tableList.items,"fields":_vm.tableList.fields,"busy":_vm.tableList.isBusy}}),_c('b-pagination',{attrs:{"align":"right","total-rows":_vm.tableList.totalRows,"per-page":_vm.tableList.perPage},on:{"input":_vm.changePage},model:{value:(_vm.tableList.currentPage),callback:function ($$v) {_vm.$set(_vm.tableList, "currentPage", $$v)},expression:"tableList.currentPage"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }