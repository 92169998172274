<template>
  <div>
    <section class="bg-white p-3 rounded shadow-sm">
      <h1>Pemusnahan Darah</h1>

      <b-form-group
        class="mt-5 w-100"
        label="Masukkan Barcode"
        label-for="barcode-input"
      >
        <b-form-input
          id="barcode-input"
          v-model="formData.barcode"
          type="text"
          placeholder="Masukkan barcode"
          @input="debouncedCheckingBarcodes"
        />
      </b-form-group>

      <b-table
        responsive
        :items="tableForm.items"
        :fields="tableForm.fields"
        :busy="tableForm.isBusy"
      >
        <template #cell(reason_failed)="{ item }">
          <b-form-select
            v-model="item.reason_failed"
            :options="[
              { value: '', text: 'Pilih Alasan Penolakan' },
              { value: 'BOCOR', text: 'BOCOR' },
              { value: 'MISLEK', text: 'MISLEK' },
              { value: 'OVER VOLUME', text: 'OVER VOLUME' },
              { value: 'CLOT', text: 'CLOT' },
              { value: 'AGREGAT', text: 'AGREGAT' },
              { value: 'FIBRIN', text: 'FIBRIN' },
              { value: 'LIPEMIK', text: 'LIPEMIK' },
              { value: 'TC MERAH', text: 'TC MERAH' },
              { value: 'REAKTIF', text: 'REAKTIF' },
              { value: 'MAYOR', text: 'MAYOR' },
              { value: 'FELNOKTOMI', text: 'FELNOKTOMI' },
              { value: 'KADALUARSA', text: 'KADALUARSA' },
            ]"
          />
        </template>
        <template #cell(note)="{ item }">
          <b-form-input
            v-model="item.note"
            type="text"
            placeholder="Masukkan catatan"
          />
        </template>
        <template #cell(action)="{ item, index }">
          <b-button variant="danger" @click="tableForm.items.splice(index, 1)"
            ><i class="ri-delete-bin-6-line"
          /></b-button>
        </template>
      </b-table>
      <div class="d-flex justify-content-end">
        <base-button variant="primary" text="Simpan" @click="handleSubmit" />
      </div>
    </section>

    <section class="bg-white p-3 rounded shadow-sm mt-5">
      <h3>Daftar Pemusnahan</h3>
      <div class="d-flex align-items-end justify-content-between">
        <p class="mb-0 mr-3">Semua ({{ tableList.totalRows }})</p>
        <b-input-group class="w-50">
          <b-input-group-prepend>
            <b-button
              variant="outline-black-50"
              class="border-right-0 pr-0 pl-2"
            >
              <i class="ri-search-line remix-icon text-primary" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="tableList.searchQuery"
            type="search"
            placeholder="Search"
            class="border-left-0"
            @input="debounceSearch"
          />
        </b-input-group>
      </div>
      <b-table
        class="mt-3"
        responsive
        :items="tableList.items"
        :fields="tableList.fields"
        :busy="tableList.isBusy"
      >
      </b-table>
      <b-pagination
        align="right"
        v-model="tableList.currentPage"
        :total-rows="tableList.totalRows"
        :per-page="tableList.perPage"
        @input="changePage"
      />
    </section>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BTable,
} from "bootstrap-vue";
import manageBloodDiscardedAPI from "../../../../api/blood_discarded/manageBloodDiscardedAPI";
import { debounce } from "lodash";

export default {
  name: "PemusnahanView",
  components: {
    BTable,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BPagination,
  },
  data() {
    return {
      formData: {
        barcode: "",
      },
      debouncedCheckingBarcodes: null,
      tableForm: {
        fields: [
          {
            key: "barcode",
            label: "Barcode",
          },
          {
            key: "blood_type",
            label: "Gol Darah",
          },
          {
            key: "volume",
            label: "Volume",
          },
          {
            key: "aftap_date",
            label: "Tanggal Pengambilan",
          },
          {
            key: "reason_failed",
            label: "Alasan Penolakan",
          },
          {
            key: "note",
            label: "Catatan",
          },
          {
            key: "action",
            label: "Aksi",
          },
        ],
        items: [],
        isBusy: false,
      },
      tableList: {
        searchQuery: "",
        fields: [
          {
            key: "barcode",
            label: "Barcode",
          },
          {
            key: "blood_type",
            label: "Gol Darah",
          },
          {
            key: "volume",
            label: "Volume",
          },
          {
            key: "aftap_date",
            label: "Tanggal Pengambilan",
          },
          {
            key: "created_at",
            label: "Tgl. Pemusnahan",
          },
          {
            key: "reason_failed",
            label: "Alasan Penolakan",
          },
          {
            key: "note",
            label: "Catatan",
          },
          {
            key: "officer_name",
            label: "Nama Petugas",
          },
        ],
        items: [],
        isBusy: false,
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
      },
    };
  },
  methods: {
    async checkBarcodes() {
      const existInTableForm = this.tableForm.items.find(
        (item) => item.barcode === this.formData.barcode
      );
      const existInTableList = this.tableList.items.find(
        (item) => item.barcode === this.formData.barcode
      );

      if (existInTableForm || existInTableList) {
        this.$bvToast.toast("Barcode ini sudah discan sebelumnya", {
          title: "Terjadi Kesalahan",
          variant: "warning",
          solid: true,
        });
        this.formData.barcode = "";
        return;
      }

      try {
        const { data: response } =
          await manageBloodDiscardedAPI.checkingBarcode(this.formData.barcode);

        if (response.code === 200 && response.success) {
          this.tableForm.items.push({
            barcode: response.data.barcode,
            blood_bag_number: response.data.blood_bag_number,
            bag_type: response.data.bag_type,
            component: response.data.component,
            blood_type: response.data.blood_type
              .replace("+", " Pos")
              .replace("-", " Neg"),
            volume: Math.round(response.data.volume),
            aftap_date: response.data.aftap_date,
            prod_date: response.data.prod_date,
            expired_date: response.data.expired_date,
            reactive: response.data.reactive,
            reason_failed: "",
            note: "",
          });
        }

        this.$bvToast.toast(
          response.code === 200
            ? "Barcode ditemukan"
            : response.data?.message || "Terjadi kesalahan",
          {
            title: response.code === 200 ? "Success" : "Terjadi Kesalahan",
            variant: response.code === 200 ? "success" : "danger",
            solid: true,
          }
        );
      } catch (e) {
        this.$bvToast.toast(e.response?.data?.message || "Terjadi kesalahan", {
          title: "Terjadi Kesalahan",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.formData.barcode = "";
      }
    },
    async handleSubmit() {
      try {
        await manageBloodDiscardedAPI.add({
          components: this.tableForm.items,
        });
        this.$bvToast.toast("Berhasil Menambahkan Data", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.tableForm.items = [];
        this.getList();
      } catch (error) {
        console.error("Error Add Blood Shipment : ", error);
        this.$bvToast.toast("Alasan Penolakan Harus Dipilih", {
          title: "Gagal Menambahkan Data",
          variant: "danger",
          solid: true,
        });
      }
    },
    async getList() {
      const { data: response } = await manageBloodDiscardedAPI.getList({
        search: this.tableList.searchQuery,
        page: this.tableList.currentPage,
        per_page: this.tableList.perPage,
      });
      this.tableList.items = response.data.data;
      this.tableList.totalRows = response.data.total;
    },
    changePage(page) {
      this.tableList.currentPage = page;
      this.getList();
    },
  },
  created() {
    this.debouncedCheckingBarcodes = debounce(this.checkBarcodes, 1000);
    this.debounceSearch = debounce(this.getList, 500);
  },
  mounted() {
    this.getList();
  },
};
</script>
